@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400&family=Rubik:wght@700&display=swap');

/* $primary: #dc5648;
$lighter-primary: #f5d5d1;
$navy: #05445b;
$blue: #33b7bf;
$green: #c6d94b;
$yellow: #ffcf33;
$orange: #fda057;
$gray: #33353d;
$light-gray: #979797;
$lighter-gray: #cccdce;
$lightest-gray: #f5f5f5;
$white: #fff;
$black: #0d0e0f; */

h1 {
	font-family: 'Rubik', 'sans-serif';
	text-transform: uppercase;
	font-size: 2.5em !important;
	/* line-height: 1.5; */
	margin-bottom: 0.5em;
	color: #dc5648;

	@media (min-width: 768px) {
		font-size: 2.72em;
		line-height: 1.125;
	}
	@media (min-width: 992px) {
		font-size: 3em;
		line-height: 1.05;
	}
};

body {
	font-size: 18px !important;
	font-family: 'Karla', sans-serif !important;
	font-weight: 400 !important;
	color: #0d0e0f !important ;
	line-height: 1.5 !important;
}

h2 {
	font-family: 'Karla', sans-serif;
	font-size: 2em !important;
	line-height: 1.5;
	margin-bottom: 0.25em;
	color: black;
	font-weight: 700;
	@media (min-width: 768px) {
		font-size: 2.4em;
	}
	@media (min-width: 992px) {
		font-size: 2.5em;
	}
}

h3 {
	font-family: 'Karla', sans-serif;
	font-size: 1.4em;
	line-height: 1.13636364;
	margin-bottom: 0.25em;
	color: black;
	font-weight: 700;
	@media (min-width: 768px) {
		font-size: 1.5em;
		line-height: 1.25;
	}
	@media (min-width: 992px) {
		font-size: 1.5em;
		line-height: 1.25;
	}
}

.header {
	width: 100%;
	display: flex;
	background-color: #fff;
	position: sticky;
	top: 0;
	border: solid 1px #d7d9e2;
	padding: 1rem;
	z-index: 200;
}